import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'src/components/Image/Image';
import { SEARCH_ICON_SVG } from 'src/lib/constants/general';

export const SearchIcon = props => {
    const { className, customStyles = {} } = props;
	return <Image customStyles={customStyles} className={className} url={SEARCH_ICON_SVG} alt={'search icon'} />;
};

SearchIcon.propTypes = {
    className: PropTypes.string
};

SearchIcon.defaultProps = {
    className: ''
};
