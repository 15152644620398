import React, { Component } from 'react';
import { getSize, responsiveInit } from '../hooks/useResponsive';

export default function withResponsive() {
	return ComposedComponent => {
		class withResponsiveComponent extends Component {
			state = {
				responsive: {},
			};

			handleResize = () => {
				this.setState({ responsive: getSize() });
			};

			componentDidMount() {
				responsiveInit(this.handleResize, this.state.responsive);
				window.addEventListener('resize', this.handleResize);
			}

			componentWillUnmount() {
				window.removeEventListener('resize', this.handleResize);
			}

			render() {
				const { ...rest } = this.props;
				const { responsive } = this.state;
				return <ComposedComponent responsive={responsive} {...rest} />;
			}
		}

		return withResponsiveComponent;
	};
}
