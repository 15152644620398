import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { IS_CLIENT } from '../constants/general';
import { getWhoAmI } from '../redux/modules/settings';
import { resolveHost } from '../whoami';

export default function withWhoAmI() {
	return ComposedComponent => {
		@connect(state => ({
			whoami: getWhoAmI(state.settings)
		}))
		class withWhoAmIComponent extends PureComponent {
			render() {
				let { whoami, ...rest } = this.props;
				if (!whoami && IS_CLIENT) {
					whoami = resolveHost(location.hostname);
				}

				return <ComposedComponent whoami={whoami} {...rest} />;
			}
		}
		return withWhoAmIComponent;
	};
}
