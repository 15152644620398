import React, { PureComponent } from 'react';
import QueryManager from 'src/lib/managers/QueryManager';
import SearchBar from 'src/components/SearchBar/SearchBar';
import Head from 'src/components/Head/Head';
import pageTracker from 'src/lib/hoc/pageTracker';
import withParsedQuery from 'src/lib/hoc/withParsedQuery';
import { SEARCH } from 'src/lib/constants/routes';
import styles from './styles.module.scss';
import withDictionary from 'src/lib/hoc/withDictionary';
import TrackingManager from 'src/lib/managers/TrackingManager';
import withWhoAmI from 'src/lib/hoc/withWhoAmI';
import { WEBOX_CIDS } from 'src/lib/constants/cids';

export async function getStaticProps() {
	return {
		props: {}, // will be passed to the page component as props
		revalidate: 1,
	};
}

@pageTracker('404 ')
@withParsedQuery()
@withDictionary()
@withWhoAmI()
export default class Page404 extends PureComponent {
	submitQuery = query => {
		const { parsedQuery, whoami, locale } = this.props;
		const { isHap } = whoami;
		delete parsedQuery['q'];
		delete parsedQuery['zby'];
		TrackingManager.trackOnNav('search', {
			keyword: query,
			cid: parsedQuery.cid,
			action: 'search',
			section: '404',
		});
		if (isHap) {
			parsedQuery.cid = WEBOX_CIDS[locale];
		}
		location.href = `${SEARCH}?${QueryManager.stringify(parsedQuery)}&q=${query}&page=1`;
	};

	render = () => {
		return (
			<div className={styles.wrapper}>
				<Head />
				<h1 className={styles.notFound}>404</h1>
				<div className={styles.desc}>{this.props.dictionary("The Page You Requested Could Not Found")}</div>
				<SearchBar
					wrapperClassName={'homePageSearchWrapper'}
					searchFormInnerClass={'homepageSearchBar'}
					searchText={this.props.dictionary("SEARCH")}
					submitQuery={this.submitQuery}
				/>
			</div>
		);
	};
}
